import displayvalue from './display-value';
import Table from './grid/table';
import widgets from './widgets/widgets';

// @TODO(2015-10-07): remove this component, it's one big antipattern
const datamodel = {
	displayvalue,
	grid: {
		Table
	},
	widgets
};

export default datamodel;
