import urlify from 'service/urlify/urlify';

export default {
	allPlans: {
		url: 'backend-commons/workflow/'
	},

	currentClinicianPlans: {
		url: (params) => `backend-commons/workflow/rest/workflow/currentAdministrator?${urlify(
			params, {
				omit: ['withNoTreatmentType', 'treatmentType', 'enabled', 'manual']
			}
		)}`
	},

	respondentAvailablePlans: {
		url: 'backend-commons/workflow/rest/workflow/currentAdministrator'
	},

	plan: {
		url: 'backend-commons/workflow/rest/workflow/'
	},

	planSingle: (planId = '') =>
		`backend-commons/workflow/rest/workflow/${planId}`,

	search: {
		url: (params) => `backend-commons/workflow/?${urlify(params)}`
	},

	triggers: {
		url: 'backend-commons/workflow/rest/trigger/all'
	},

	clinicianGroup: (relationId = '') =>
		`backend-commons/workflow/rest/administratorGroup/${relationId}`,

	clinicianGroups: (planId) =>
		`backend-commons/workflow/rest/administratorGroup/workflow/${planId}`,

	revision: {
		url: 'backend-commons/workflow/rest/workflowhistory/'
	},

	revisions: {
		url: (planId) => `backend-commons/workflow/rest/workflowhistory/by_workflow/${planId}`
	},

	copy: {
		url () {
			return `backend-commons/workflow/rest/workflow/${this.getId()}/copy/`;
		}
	},

	expiredAssessment: (relationId = '') => {
		const suffix = relationId ? `/${relationId}` : '';
		return `backend-commons/workflow/rest/trigger/assessment/expired${suffix}`;
	},

	expiredAssessments: (planId) =>
		`backend-commons/workflow/rest/trigger/assessment/expired/all?workflow=${planId}`,

	flag: (relationId = '') => {
		const suffix = relationId ? `/${relationId}` : '';
		return `backend-commons/workflow/rest/trigger/flag${suffix}`;
	},

	flags: (planId) => `backend-commons/workflow/rest/trigger/flag/all?workflow=${planId}`,

	availableRoles: (planId) => `backend-commons/workflow/rest/workflow/${planId}/available-roles/`
};
