import { required } from 'vuelidate/lib/validators';

const identity = {
	identity: {
		required
	}
};

export const validation = {
	validations: {
		formData: {
			...identity
		}
	}
};

export const assessmentValidation = {
	validations: {
		formData: {
			assessment: {
				required
			},
			...identity
		}
	}
};

export const treatmentValidation = {
	validations: {
		formData: {
			treatmentType: {
				required
			},
			...identity
		}
	}
};

export const respondentCustomAttributeHistory = {
	validations: {
		formData: {
			assessment: {
				required
			},
			...identity
		}
	}
};
