import { forEach, noop } from 'lodash';
import { Model } from 'backbone';
import can from 'service/acl/acl-can';
import t from 'service/lang/translate';
import store from 'store';
import cwalert from 'service/cwalert';

export const inputTypes = {
	BOOL: 'checkbox',
	STRING: 'text',
	TEXT: 'textarea',
	INTEGER: 'number',
	DROPDOWN: 'select',
	EMAIL: 'email',
	TIME: 'time'
};

export const chooseRoute = (router) => {
	forEach(router, (route) => {
		if (route.when()) {
			route.then();
			return false;
		}
		return true;
	});
};

export const formCfgFactory = ({ setting }) => {
	const type = inputTypes[setting.systemSettingType] || 'text';
	const readonly = !can.edit('system.settings.settings');
	const model = new Model(setting);
	const label = setting.systemSettingLabel;
	const maxLength = setting.systemSettingMaxLength || Infinity;
	const validators = setting.systemSettingMaxLength ?
		{
			validator: (value) => value.length <= maxLength,
			invalidMessage: t('Text too long, max__ {num} characters', {
				num: maxLength
			})
		} :
		{};

	return {
		classNames: ['no-fieldset-margin'],
		model,
		readonly,
		preventSave: true,
		loader: false,
		fields: [{
			key: 'systemSettingValue',
			type,
			label: t(`system-settings.${label}`),
			validators,
			hint: setting.systemSettingMaxLength ?
				t('Maximum length {maxSize} characters', { maxSize: maxLength }) :
				'',
			onChange ({ validation = [] }) {
				Promise.all(validation).then(
					() => {
						store.dispatch('systemSettings/update', model.toJSON()).then(() => {
							cwalert.success(t('Success'));
						});
					},
					noop
				);
			},
			customize (view) {
				// Workaround for checkboxes accessibility
				if (view.model.get('type') === 'checkbox') {
					const uniqueId = `systemSettingValue-${label}`;
					// eslint-disable-next-line lodash/prefer-lodash-method
					const $label = view.$el.find('.form-view__label-text');
					// eslint-disable-next-line lodash/prefer-lodash-method
					const $input = view.$el.find('input');

					$label.attr('id', uniqueId);
					$input.attr('aria-labelledby', uniqueId);
				}
			}
		}]
	};
};

export const removeAttributesConfig = () => ({
	icon: 'delete',
	warning: true,
	title: t('Remove attribute'),
	message: t('general-list.ConfirmRemove')
});
