import urlify from 'service/urlify/urlify';

export default {
	single: {
		url: 'backend-commons/information-package/'
	},

	listAll: {
		url: 'backend-commons/information-package/'
	},

	search: {
		url: (params) => `backend-commons/information-package/?${urlify(params)}`
	},

	listAvailable: {
		url: 'backend-commons/information-package/?currentAdministrator=1'
	},

	publish: {
		url: (id) => `backend-commons/information-package/${id}/publish`
	},

	clinicianGroups: (contentPackageid) =>
		`backend-commons/information-package/${contentPackageid}/administrator-group/`,

	clinicianGroup: ({ contentPackageId, clinicianGroupId }) =>
		`backend-commons/information-package/${contentPackageId}/administrator-group/${clinicianGroupId}`,

	singleElement: {
		url () {
			return `backend-commons/information-package/${this.get('informationPackage')}/element`;
		}
	},
	listAllElements: {
		url: (contentPackage) => `backend-commons/information-package/${contentPackage}/element`
	},

	category: {
		url: () => 'backend-commons/information-package/category/'
	}
};
