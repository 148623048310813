import cwalert from 'cwalert';
import selectOptions from '../helpers/select-options';
import standardDialog from './standard-dialog';
import store from 'store';
import t from 'translate';
import $ from 'jquery';
import { assign, filter, forOwn, get, invoke, isArray, isObject, transform } from 'lodash';

export default (modalCfg) => ({
	store,
	components: { standardDialog },
	data: {
		classPrefix: 'data-integration',
		item: modalCfg.item,
		processing: false,
		show: true,
		unsubscribe: null,
		configFields: ['classPrefix', 'item', 'onClose', 'onSubmit', 'show'],
		fields: ['acl', 'edit', 'title', 'type'],
		warnings: []
	},
	created () {
		this.appendElement();
		this.subscribeRemove();
	},
	computed: {
		config () {
			return assign(
				{ loading: false },
				transform(this.configFields, (result, element) => {
					result[element] = this[element];
				}, {}),
				transform(this.fields, (result, element) => {
					result[element] = modalCfg[element] || false;
				}, {})
			);
		},
		emptyEnterpriseToBeRemoved () {
			return modalCfg.type === 'code' || modalCfg.type === 'ruleSet';
		},
		ruleSetData () {
			return { id: this.$store.getters['urlParams'].ruleSetId };
		}
	},
	methods: {
		appendElement () {
			const $el = $('<div />');
			$('body').append($el);
			this.$mount($el[0]);
		},
		filterEnabled: (list) => filter(list, 'enabled'),
		getRuleSetId () {
			return this.ruleSetData ? Number(this.ruleSetData.id) : null;
		},
		onClose () {
			this.show = false;
			this.$destroy();
			this.$el.parentNode.removeChild(this.$el);
		},
		dataMapping (data) {
			data.ruleSet = this.getRuleSetId();
			data.superAssessment = data.superAssessment ? Number(data.superAssessment) : null;
		},
		roleMappingData (data) {
			data.dstRoleId = get(this, 'item.id', null);
			data.ruleSet = this.getRuleSetId();
		},
		onSubmit () {

			const data = transform(this.item, (result, element, key) => {
				result[key] = isObject(element) && !isArray(element) ? element.value : element;
			});

			if (modalCfg.type === 'dataMapping') {
				this.dataMapping(data);
			} else if (modalCfg.type === 'roleMapping') {
				this.roleMappingData(data);
			} else if (this.emptyEnterpriseToBeRemoved && !data.enterprise) {
				delete (data.enterprise);
			}

			this.processing = true;
			store.dispatch(`dataIntegration/${modalCfg.action}`, data)
				.then(() => {
					cwalert.success(modalCfg.success);
					this.processing = false;
					this.onClose();
					invoke(modalCfg, 'refresh');
				})
				.catch((err) => {
					this.warnings = [];

					if (!err.responseJSON.success) {
						forOwn(err.responseJSON.errors, (value, key) => {

							const field = this.t(`dataIntegration.errorFields.${key}`);
							const message = this.t(`dataIntegration.errors.${value}`);
							this.warnings.push(`${field} - ${message}`);
						});
						setTimeout(() => {
							this.warnings = [];
						}, 5000);
					}
					this.processing = false;
				});
		},
		scrollModal () {
			const modalHandler = $('.modal-dialog__window');

			if (modalHandler[0]) {
				if (modalHandler[0].scrollTo) {
					modalHandler[0].scrollTo(0, 0);
				// MS EDGE
				} else {
					modalHandler[0].scrollTop = 0;
				}
			}
		},
		selectOptions,
		subscribeRemove () {
			this.unsubscribe = store.subscribe((mutation) => {
				if (mutation.type === 'setCardData' && this.$el) {
					this.$destroy();
					this.unsubscribe();
				}
			});
		},
		t
	}
});
