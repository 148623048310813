import urlify from 'service/urlify/urlify';
import { omit } from 'lodash';

const administrator = 'backend-commons/administrator';

export default {
	clinician: {
		url: 'backend-commons/administrator/rest/admin/'
	},
	allClinicians: {
		url: 'backend-commons/administrator/'
	},

	listGroups: {
		url: 'backend-commons/administrator/rest/group/all'
	},

	singleGroup: {
		url: 'backend-commons/administrator/rest/group/'
	},

	singleAttributeDefinition: {
		url: 'backend-commons/administrator/rest/attributemeta/'
	},

	listAttributeDefinitions: {
		url: 'backend-commons/administrator/rest/attributemeta/all'
	},

	singleAttributeValue: {
		url: 'backend-commons/administrator/rest/attribute/'
	},

	listAttributeValues: {
		url: (clinicianId) => `backend-commons/administrator/rest/attribute/by-administrator/${clinicianId}`
	},

	groupHasAccessToRespondentGroups: {
		url: (groupId) => `backend-commons/administrator/rest/administrator_group_has_respondent_group/by-administratorgroup/${groupId}`
	},

	groupHasAccessToRespondentGroup: {
		url: 'backend-commons/administrator/rest/administrator_group_has_respondent_group/'
	},

	searchClinicians: {
		url: (params) => `backend-commons/administrator/?${urlify(params, { omit: ['treatment'] })}`
	},

	searchAvailableClinicians: (params) => {
		const { respondentId, treatmentTypeId } = params;
		const treatmentType = treatmentTypeId ? `treatmentType/${treatmentTypeId}` : '';

		return `${administrator}/available-for-treatment/respondent/${
			respondentId
		}/${
			treatmentType
		}/?${
			urlify(omit(params, [`respondentId`, `treatmentTypeId`]))
		}`;
	},

	clinicianInGroup: {
		url: 'backend-commons/administrator/rest/administrator_has_group/'
	},

	clinicianInGroups: {
		url: (clinicianId) => `backend-commons/administrator/rest/administrator_has_group/by-administrator/${clinicianId}`
	},

	clinicianInRole: {
		url: 'backend-commons/administrator/rest/administrator_has_role/'
	},

	clinicianInRoles: {
		url: (clinicianId) => `backend-commons/administrator/rest/administrator_has_role/by-administrator/${clinicianId}`
	},

	clinicianAssignedGroups: {
		url: (clinicianId) => `backend-commons/administrator/${clinicianId}/group/`
	},

	clinicianAssignedGroup: {
		url: ({ clinicianId, clinicianGroupId }) => `backend-commons/administrator/${clinicianId}/group/${clinicianGroupId}`
	},

	clinicianAssignedRoles: {
		url: (clinicianId) => `backend-commons/administrator/${clinicianId}/role/`
	},

	clinicianAssignedRole: {
		url: ({ clinicianId, clinicianRoleId }) => `backend-commons/administrator/${clinicianId}/role/${clinicianRoleId}`
	},

	deniedClinician: {
		url: (respondentId) => `backend-commons/respondent/${respondentId}/denied-administrator/`
	},

	addDeniedClinician: {
		url: ({ respondentId, clinicianId }) => `backend-commons/respondent/${respondentId}/denied-administrator/${clinicianId}`
	},

	delDeniedClinician: {
		url: ({ respondentId, clinicianId }) => `backend-commons/respondent/${respondentId}/denied-administrator/${clinicianId}`
	},

	lockStatus: (clinicianId) =>
		`backend-commons/username-authentication-locks/user/clinician/${clinicianId}`,
	unlock: (clinicianId) =>
		`backend-commons/username-authentication-locks/user/clinician/${clinicianId}`
};
