import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';
import systemSettings from 'system-settings';
import acl from 'acl';
import appContext from 'app-context';
import { isNull } from 'lodash';
import { clinicianName } from 'service/display-name/display-name';

export default SingleEntity.extend({
	urlRoot: urls.clinician.url,
	idAttribute: 'administratorId',
	eventNamespace: 'clinician',
	checkpoint: 'system.administrators.administrators',

	defaults: () => ({
		administratorFirstName: '',
		administratorLastName: '',
		administratorUsername: null,
		administratorPassword: null,
		administratorPhone: null,
		administratorEmail: null,
		administratorTestAccount: false,
		administratorAccountEnabled: true,
		administratorLanguageId: systemSettings.getInteger('DEFAULT_ADMIN_LANGUAGE'),
		administratorTimezoneName: systemSettings.getString('DEFAULT_ADMIN_TIMEZONE'),
		accessRoles: []
	}),

	canBeDeleted () {
		const isCurrentUser = appContext.get('user').get('userId') === this.getId();

		if (this.checkpoint) {
			return !isCurrentUser && acl.checkAccess({
				op: 'ADMINISTRATOR',
				checkpoint: 'system.administrators.administrators'
			});
		}

		return true;
	},

	label: {
		administratorFirstName: t('First name'),
		administratorLastName: t('Last name'),
		administratorUsername: t('Username'),
		administratorPassword: t('Password'),
		administratorPhone: t('Phone'),
		administratorEmail: t('Email'),
		administratorAccountEnabled: t('Account enabled'),
		administratorTestAccount: t('Test account'),
		administratorTimezone: t('Timezone'),
		administratorLanguage: t('Language')
	},

	validate (attrs) {
		if (attrs.noValidation) {
			return;
		}
		const keys = [];
		const msgs = [];
		const usernameRegex = new RegExp(systemSettings.getString('CLINICIAN_USERNAME_REGEX'));
		const passwordRegex = new RegExp(systemSettings.getString('CLINICIAN_PASSWORD_REGEX'));
		const {
			accessRoles, administratorEmail, administratorUsername, administratorPassword
		} = attrs;

		if (administratorEmail && !administratorEmail.match(/^[^@]+@[^@]+\..{2,3}$/)) {
			keys.push('administratorEmail');
			msgs.push(t('admin.account.invalid-email-address'));
		}

		if (this.isNew() && !usernameRegex.test(administratorUsername)) {
			keys.push('administratorUsername');
			msgs.push(t('Username has to match required pattern'));
		}

		if (!administratorUsername || !administratorUsername.length) {
			keys.push('administratorUsername');
			msgs.push(t('Username shouldn\'t be empty'));
		}

		if (!isNull(administratorPassword) && administratorPassword.length < 3) {
			keys.push('administratorPassword');
			msgs.push(t('Password needs to be longer than 2 characters'));

		} else if (!isNull(administratorPassword) && !passwordRegex.test(administratorPassword)) {
			keys.push('administratorPassword');
			msgs.push(t('Password has to match required pattern'));
		}

		if (
			this.isNew() &&
			systemSettings.getBoolean('MANDATORY_CLINICIAN_ROLES') &&
			accessRoles.length < 1
		) {
			keys.push('accessRoles');
			msgs.push(t('Access role(s) should be set'));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	},

	getName () {
		return {
			firstName: this.get('administratorFirstName'),
			lastName: this.get('administratorLastName'),
			login: this.get('administratorUsername')
		};
	},

	displayName () {
		return clinicianName(this.toJSON());
	},

	getTimezone () {
		return this.get('administratorTimezoneName');
	},

	// used for searching etc
	match (match) {
		return match.test(this.get('administratorFirstName') + this.get('administratorLastName'));
	}
});

