import t from 'translate';
import formViewDialog from 'service/form-view-dialog';

export default ({ entity, title }) => () => {
	const formConfig = {
		name: 'name',
		model: new entity(),
		successMessage: t('Successfully added'),
		fields: [{
			key: 'name',
			type: 'text',
			label: t('Category name'),
			mandatory: true,
			focus: true,
			max: 64
		}]
	};

	const dialogConfig = { title };

	formViewDialog({ formConfig, dialogConfig });
};
