import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import icon from 'service/icon';
import store from 'store';
import t from 'translate';
import $ from 'jquery';

export default ({ data, item, td, tile, onSuccess, onError }) => {
	const success = onSuccess || function () {
		cwalert.success(t(data.success));
		tile.runQuery();
	};

	const error = onError || function () {
		cwalert.error(t('Error removing item'));
	};

	const $delete = $('<button />')
		.addClass('icon-button__button')
		.html(icon('delete', {
			title: t(data.title),
			small: true
		}));
	$(td).html($delete);

	$delete.on('click', () => {
		confirmation({
			icon: 'delete',
			warning: true,
			title: t(data.title),
			message: t(data.question)
		}).done(() => {
			const start = tile.viewModel.get('start');

			if (start > 0) {
				const limit = tile.viewModel.get('limit');
				tile.viewModel.set('start', 0);
				tile.viewModel.set('limit', start + limit);
			}

			store.dispatch(data.action, item.id).then(success, error);
		});
		return false;
	});
};
